'use strict';

$(document).ready(function () {


  if(!is_touch_device() && $(window).width() > 1200 && $('.page-footer-graph').length > 0) {
    var imgParallaxFactor = {
      0: {
        img: .3,
        imgShadow: -.3,
      },
    }

    $(window).on('mousemove', function(e) {
      var containerWidth = $(window).width();
      var containerHeight = $(window).height();

      var positionX = e.pageX;
      var positionY = e.pageY;

      var moveX = positionX / containerWidth * 100 - 50;
      var moveY = positionY / containerHeight * 100 - 50;

      $('.page-footer-graph').each(function (index, item) {
        var $container = $(item);
        $('.page-footer-graph-globe', $container).css({transform: 'translate(' + (moveX * imgParallaxFactor[index]['img'] ) + 'px, ' + (moveY * imgParallaxFactor[index]['img'] ) + 'px)'});
        $('.page-footer-graph-logo', $container).css({transform: 'translate(' + (moveX * imgParallaxFactor[index]['imgShadow'] ) + 'px, ' + (moveY * imgParallaxFactor[index]['imgShadow'] ) + 'px)'});
      });
    });
  }
});
