$( document ).ready(function() {

  $('.page-footer-newsletter-form').submit(function( event ) {
    var $form = $(this);
    var url = $form.attr('action');
    $('.form-feedback', $form).remove();

    $.ajax({
      type: "POST",
      url: url,
      data: {
        email: $form.find('[name="email"]').val(),
        address: 1
      }
    }).done(function(response) {

      $ok = $('<span class="has-success form-feedback">Subscription Confirmed</span>');
      $form.append($ok);
      /*
      if(response.Status == 'ok') {
        $ok = $('<span class="has-success form-feedback">' + response.Message + '</span>');
        $form.append($ok);
      } else {
        $error = $('<span class="has-error form-feedback">' + response.Message + '</span>');
        $form.append($error);
      }
      */



    })
    .fail(function() {

      $ok = $('<span class="has-success form-feedback">Subscription Confirmed</span>');
      $form.append($ok);

      /*
      $error = $('<span class="has-error form-feedback">Error</span>');
      $form.append($error);
      */
    });

    return false;
  });

});
