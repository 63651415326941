$( window ).on('start', function (e) {
	!(function ($doc, $win) {
		if($(".translate-card").length > 0) {
			var screenWidth = $win.screen.width / 2,
				screenHeight = $win.screen.height / 2,
				$elems = $doc.getElementsByClassName("translate-card"),
				validPropertyPrefix = '',
				otherProperty = 'perspective(1000px)',
				elemStyle = $elems[0].style;

			if(typeof elemStyle.webkitTransform == 'string') {
				validPropertyPrefix = 'webkitTransform';
			} else if (typeof elemStyle.MozTransform == 'string') {
				validPropertyPrefix = 'MozTransform';
			}

			$doc.addEventListener('mousemove', function (e) {
				var centroX = e.clientX - screenWidth,
					centroY = screenHeight - (e.clientY + 13),
					degX = centroX * 0.02,
					degY = centroY * 0.02,
					$elem

				for (var i = 0; i < $elems.length; i++) {
					$elem = $elems[i];
					$elem.style[validPropertyPrefix] = otherProperty + 'rotateY('+ degX +'deg)  rotateX('+ degY +'deg)';
				};
			});
		}
	})(document, window);
});
